.early-layout {
    //no padding et left and right
    &--no-plr {
        padding-left: 0;
        padding-right: 0;
    }
    //same padding than col-*-*
    &--col-plr {
        padding-left: 15px;
        padding-right: 15px;
    }
    &--col-mlr {
        margin-left: 15px;
        margin-right: 15px;
    }
    &--margin-b-15 {
        margin-bottom: 15px;
    }

    .early-clear {
        clear: both;
    }
}