@use "include-media" as im;

html, body {
    height: 100%;
}

body {
    .body-container {
        display: flex;
        flex-flow: column;
        height: 100%;

        &__header {
            flex: 0 1 auto;
            width: 100%;
        }
        &__main {
            flex: 1 1 auto;
        }
        &__footer {
            flex: 0 1 auto;
        }
    }

    .container {
        @include im.media(">=small") {
            & {
                width: 750px
            }
        }
        
        @include im.media(">=medium") {
            & {
                width: 970px
            }
        }
        
        @include im.media(">=large") {
            & {
                width: 1180px
            }
        }
        
        @include im.media(">=xlarge") {
            & {
                width: 1380px
            }
        }

        @media (min-width: 1660px) {
            & {
                width: 1640px;
            }
        }
    }

    @include im.media("<small") {
        .body-container__header.container {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@include im.media(">xxsmall") {
    .visible-xxs {
        display: none!important;
    }
}

@include im.media("<=xxsmall") {
    .hidden-xxs {
        display: none!important;
    }

    .visible-xxs {
        display: block!important;
    }

    .col-xxs-12 {
        width: 100%;
    }
}