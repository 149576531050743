@use "sass:map";
@use '../../base/scss/vars' as *;

$className: '.early-icon';

%early-icon-base {
    content: '';
    -webkit-mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    position: absolute;
    height: 20px;
    width: 15px;
    //To check
    top: -10px;
    right: -12px;
}

#{$className} {
    &--before::before,
    &--after::after {
        @extend %early-icon-base;
    }
}

//Not into previous block to avoid adding space
#{$className+"--file"+$className+"--before::before"},
#{$className+"--file"+$className+"--after::after"} {
    -webkit-mask-image: url('/theme/earlycareer/icons/file.svg');
}

@each $status, $color in $fileColorPerStatus {
    #{$className+"--file--"+$status+$className+"--before::before"},
    #{$className+"--file--"+$status+$className+"--after::after"} {
        background-color: $color;
    }
}
