@use '../../base/scss/vars' as *;

.early-table {
    border-collapse: collapse;

    &:not(.early-table--no-width) {
        width: 100%;
    }

    &--spacing {
        border-collapse: separate;
        border-spacing: 5px 0;
    }

    &--border {
        &, tr, td, th {
            border: 1px solid $line;
        }
    }

    &--selected-blue {
        tr.selected {
            border-top: 3px solid $blue-active;
            border-bottom: 3px solid $blue-active;
            background: rgba(0, 134, 162, 0.15) !important;
        }
    }

    &__heading {
        th {
            background-color: $blue-active;
            color: $white;
            text-align: center;
            padding: 14px;
            font-weight: 600;
        }
    }

    &__row {
        cursor: pointer;

        &:not(:first) {
            border-top: 1px solid $line;
        }

        &:nth-of-type(odd) {
            background-color: $table-row-odd-bg;
        }
        &:nth-of-type(even) {
            background-color: $table-row-even-bg;
        }
        &.selected:nth-of-type(odd) {
            background-color: $table-row-odd-selected-bg;
        }
        &.selected:nth-of-type(even) {
            background-color: $table-row-even-selected-bg;
        }

        td {
            padding: 14px;
            font-weight: normal;
            text-align: left;
            font-size: $size-small;
        }

        &:hover {
            background-color: $light-blue;
            border-radius: 10px;
        }
    }
}

.table-responsive .early-table {
    //NOT TESTED
    //&.early-table--fixed-first-row {
    //    thead tr:first-child {
    //        position: sticky;
    //        top: 0;
    //        z-index: 1;
    //    }
    //}

    &.early-table--fixed-first-column {
        thead tr:first-child th:first-child, 
        tbody td:first-child {
            position: sticky;
            left: 0;
            z-index: 2;
        }

        thead tr:first-child th:first-child {
            background-color: $white;

            &:not(:empty) {
                background-color: $blue-active;
            }
        }

        tbody tr {
            & td:first-child {
                background-color: $white;
            }
            &:nth-of-type(odd) td:first-child {
                background-color: $table-row-odd-bg;
            }

            &:hover {
                &:nth-of-type(even) td:first-child {
                    background-color: $light-blue;
                }
                &:nth-of-type(odd) td:first-child {
                    background-color: $light-blue;
                }
            }
        }

        //@TODO Not work
        //https://stackoverflow.com/questions/50361698/border-style-do-not-work-with-sticky-position-element
        //We will see later for the solution, no time now and it's not used on candidate access
        //&.early-table--border {
        //    tbody tr td:first-child {
        //        border-right: 1px solid $line;
        //    }
        //}
    }
}

.early-table.dataTable {
    &.early-table--border thead th,
    &.early-table--border.no-footer {
        border-bottom: 1px solid $line;
    }
    &:not(.early-table--spacing) {
        border-collapse: collapse;
    }
}
