@use '../../base/scss/vars' as *;

footer {
    background-color: $light-grey;
    min-height: 60px;

    .layout-footer {
        &__contact-info {
            padding: 10px;
            text-align: center;

            p {
                margin: 0;
            }
        }
    }
}
