@use '../../base/scss/vars' as *;
@use "include-media" as im;

/*@include im.media("<medium") {
    display: none;
}*/

header {
    min-height: 80px;
    background-color: $white;

    .header__xs {
        &__ctn {
            display: flex;
            align-items: center;
            margin-top: 15px;

            @include im.media("<=xxsmall") {
                & {
                    flex-direction: column;
                }
            }

            &__hamburger {
                float: none;
                border-color: #333;
                margin-right: 15px;

                .icon-bar {
                    background-color: #333;
                }
            }

            &__logo {
                @include im.media("<=xxsmall") {
                    & {
                        text-align: center;
                    }
                }
            }

            &__menu__xxs {
                display: block;
                width: 100%;
                cursor: pointer;
                padding: 15px 0;
                color: $dark-blue;
                text-align: center;
                font-weight: 600;

                &--knp {
                    padding-bottom: 0;
                }
            }

            &__user-icon {
                margin-left: auto;

                & .header__user__icon {
                    margin-right: 0;
                }
            }
        }

        &__menu {
            height: 100%;
            width: 0;

            &--show {
                width: 100%;

                .header__xs__menu__bg, .header__xs__menu__ctn {
                    position: fixed;
                    top: 0;
                    display: block;
                }

                .header__xs__menu__bg {
                    width: 100%;
                    left: 0;
                }
                .header__xs__menu__ctn {
                    width: 75%;

                    @include im.media("<=xxsmall") {
                        & {
                            width: 100%;
                        }
                    }
                }
            }

            &__bg {
                height: 100%;
                z-index: 99;
                background: #000;
                opacity: .8;
                display: none;
            }

            &__ctn {
                z-index: 100;
                background-color: $white;
                opacity: 1;
                overflow-x: hidden;
                padding: 15px;
                padding-top: 60px;
                height: 100%;
                display: none;
                /*transition: 0.3s; not work, we will see that later*/
                color: $black;

                &--left {
                    left: 0;
                }

                &--right {
                    right: 0;

                    padding-left: 0px;
                    padding-right: 15px;

                    .header__xs__menu__ctn__title {
                        left: initial;
                        right: 15px;
                        text-align: right;
                    }

                    .header__xs__menu__ctn__closebtn {
                        right: initial;
                        left: 15px;
                    }
                }

                &__title {
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: $size-default;
                    border-bottom: 1px solid $black;
                    width: 100%;
                    padding-bottom: 15px;
                }

                &__closebtn {
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    right: 15px;
                    font-size: $size-icon-close;
                }

                .header__xs__menu__ctn__knp li {
                    &.menu-dropdown, &.dropdown-submenu {
                        padding-left: 10px;

                        > a {
                            padding-left: 18px;

                            &::before {
                                content: "";
                                border: solid $black;
                                border-width: 0 3px 3px 0;
                                display: inline-block;
                                padding: 3px;
                                transform: rotate(-45deg);
                                height: 5px;
                                width: 5px;
                                border-bottom-right-radius: 2px;
                                
                                position: absolute;
                                top: 15px;
                                left: 3px;
                            }

                            &.dropdown--open::before {
                                transform: rotate(45deg);
                            }
                        }

                        ul.dropdown-menu.dropdown--open {
                            display: flex;
                            flex-direction: column;

                            position: relative;
                            float: none;
                            width: 100%;
                        }
                    }

                    &.dropdown-submenu > a {
                        &::before {
                            top: 13px;
                            left: 13px;
                        }
                        &::after {
                            content: "";
                        }
                    }

                    &.dropdown-submenu > .dropdown-menu {
                        left: 15px;
                    }

                    &.dropdown-submenu:hover > .dropdown-menu {
                        display: none;
                    }

                    a {
                        color: $black;
                        font-weight: 600;
                    }
                }

                .header__xs__menu__ctn__user {
                    &__profile {
                        color: $black;
                        font-weight: 600;
                        text-align: center;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        width: 100%;
                        display: block;
                        margin-bottom: 15px;
                    }

                    &__version {
                        padding-left: 5px;
                        position: absolute;
                        bottom: 5px;
                    }

                    & .dropdown-menu {
                        display: flex;
                        flex-direction: column;
                        box-shadow: none;

                        position: relative;
                        float: none;
                        width: 100%;
                    }
                }
            }
        }
    }

    .header__user {
        &__dropdown {
            &__name {
                background-color: $blue;
                color: $beige;
                font-weight: 600;
                padding: 8px 16px;
            }
        }

        &__icon {
            background-color: $dark-blue;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            padding: 12px 15px;
        }
    }

    .header__main {
        a {
            font-size: $size-default;
            font-weight: 600;
            text-decoration: none;
        }

        &__logo, &__menu {
            padding: 24px 0;
        }

        &__user {
            background-color: $blue;
            min-height: 80px;

            @include im.media(">=small") {
                position: absolute;
                top: 0;
                right: 0;
                padding: 15px 31px;
                border-bottom-left-radius: 20px;
            }

            &__ctn {
                display: flex;
                flex-direction: row;
                align-items: center;

                &__icon {
                    margin-right: 21px;
                }

                &__profil {
                    &--anon a, &__name {
                        //Here and not in _ctn a to avoid white on white into dropdown
                        color: $beige;
                    }

                    &--anon::after {
                        content: url('/theme/earlycareer/icons/fleche.svg');
                        margin-left: 15px;
                        height: 16px;
                        width: 24px;
                        filter: brightness(0) invert(1);
                    }

                    &__name span::after {
                        content: "";
                        border: solid $white;
                        border-width: 0 3px 3px 0;
                        display: inline-block;
                        padding: 3px;
                        transform: rotate(45deg);
                        height: 5px;
                        width: 5px;
                        border-bottom-right-radius: 2px;
                        margin-left: 15px;
                        position: absolute;
                        bottom: 40%;
                    }
                }
            }

            &__version {
                position: absolute;
                bottom: 2px;
                right: 5px;
                color: $white;
            }
        }

        &__menu {
            .nav {
                display: flex;
                align-content: center;
                justify-content: space-around;
                align-items: center;
                width: 100%;

                li {
                    &:not(:first-child) {
                        margin-left: 5px;
                    }
                    &:not(:last-child) {
                        margin-right: 5px;
                    }

                    a {
                        color: $black;
                        padding: 0;
                        color: #2b2b28;
                        font-size: $size-default;

                        &:hover {
                            background-color: transparent;
                        }
                    }

                    &.current {
                        position: relative;

                        &::after {
                            content: "";
                            width: 100%;
                            height: 6px;
                            background-color: $blue;
                            position: absolute;

                            @include im.media('>=large') {
                                & {
                                    top: 50px;
                                }
                            }
                            @include im.media('<large') {
                                & {
                                    top: 38px;
                                }
                            }
                        }
                    }
                }

                li.menu-dropdown {
                    &:hover {
                        cursor: pointer;

                        & > .dropdown-menu {
                            display: flex;
                            flex-direction: column;

                            li {
                                padding: 12px;
                                margin-left: 5px;
                                margin-right: 5px;

                                &.current::after {
                                    top: initial;
                                    bottom: 0;
                                    width: calc(100% - 24px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    //fix dropdown, bootstrap css not allow it
    & .dropdown-menu.dropdown-menu-right {
        left: auto;
        right: 0;

        //arrow at right side
        &::before {
            right: 9px;
            left: auto;
        }
        &::after {
            right: 10px;
            left: auto;
        }
    }
}
