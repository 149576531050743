@use '../../base/scss/vars' as *;

.early-list {
    &__item {
        padding: 20px;

        border-top: 1px solid $line;
        &:last-child {
            border-bottom: 1px solid $line;
        }
        &:nth-child(odd) {
            background-color: $table-row-odd-bg;
        }
    }
}