@use "sass:math";
@use '../../base/scss/vars' as *;
@use "include-media" as im;

.early-stepper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end; //flex-end if text up, flex-start if text down
    flex-direction: row;
    padding-left: 0;

    @include im.media('<medium') {
        & {
            flex-direction: column;
        }
    }

    $icon_width: 60px;
    $icon_height: 60px;
    $medium_space_between_icon: 30px;

    &__item {
        display: block;
        width: 100%;

        @include im.media('<medium') {
            & {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            &__text {
                order: 2;
            }
            &__icon {
                order: 1;
            }
        }

        &__text {
            color: $white;
            font-size: $size-default;
            font-weight: 600;
            color: $white;
            text-align: center;

            @include im.media('<medium') {
                & {
                    text-align: start;
                    margin: 0;
                    margin-left: 15px;
                    margin-bottom: $medium_space_between_icon;
                }
            }
        }

        &__icon {
            display: block;
            position: relative;
            width: 100%;
            height: $icon_height;

            @include im.media('<medium') {
                & {
                    width: $icon_width;
                    height: $icon_height+$medium_space_between_icon;
                }
            }

            @include im.media('>=medium') {
                & {
                    text-align: center;
                }
            }

            &::before, &::after {
                display: block;
                position: absolute;
                top: 50%;
                width: 50%;
                height: 3px;
                margin-top: -1px;
                background-color: $blue-active;
                content: '';
                z-index: 1;

                @include im.media('<medium') {
                    & {
                        width: 3px;
                        //height: math.div($medium_space_between_icon, 2);
                        height: 50%;
                    }
                }
            }

            &::before {
                left: 0;

                @include im.media('<medium') {
                    & {
                        left: calc(50% - 1.5px); //1.5 => width/2
                        top: calc(-50% + 2px);
                    }
                }
            }
            &::after {
                right: 0;

                @include im.media('<medium') {
                    & {
                        left: calc(50% - 1.5px); //1.5 => width/2
                        right: unset;
                        top: unset;
                        bottom: 0;
                    }
                }
            }

            &__img {
                display: inline-block;
                position: relative;
                z-index: 5;
                width: $icon_width;
                height: $icon_height;
                border-radius: 50%;
                border: 8px solid $blue-active;
                background-color: $dark-blue;
            }
        }

        &:first-child &__icon::before,
        &:last-child &__icon::after {
            display: none;
        }

        &.completed &__icon__img {
            border: 0;
            background-image: url('/theme/earlycareer/icons/check.png');
            background-position: center;
            background-repeat: no-repeat;
        }

        &.completed &__icon__img,
        &.current &__icon__img {
            background-color: $white;
        }

        &.completed &__icon::before,
        &.completed &__icon::after,
        &.current &__icon::before {
            background-color: $white;
        }

        &.current &__icon::before {
            background: linear-gradient(
                90deg,
                rgba(255,255,255,1) 0%,
                rgba(0,177,215,1) 100%
            );

            @include im.media('<medium') {
                & {
                    background: linear-gradient(
                        180deg,
                        rgba(255,255,255,1) 0%,
                        rgba(0,177,215,1) 100%
                    );
                }
            }
        }
    }
}