@use "sass:list";
@use "sass:map";
@use "sass:meta";
@use "sass:selector";
@use 'vars' as *;
@use "include-media" as im;

$classEarlyBtn: '.btn-early';

//If you add a color, also need to add the mixins
$colorList: (
    'primary': 'p',
    'blue-white': 'b-w',
    'white-blue': 'w-b',
    'white-grey': 'w-gy',
    'white-green': 'w-gn',
    'white-red': 'w-r',
    'transparentWhite-blue': 'tw-b'
);

$iconPath: '/theme/earlycareer/icons/';
$iconList: (
    'arrow': $iconPath+'fleche.svg',
    'replace': $iconPath+'replace.svg',
    'valid': $iconPath+'valid.svg',
    'refuse': $iconPath+'refuse.svg',
    'file': $iconPath+'file.svg',
);
$iconDefaultColor: (
    'arrow': $blue,
    'replace': $blue,
    'valid': $green,
    'refuse': $red,
    'file': $blue
);

@mixin btn-early-color ($bg, $border, $txt, $hovBG, $hovBorder, $hovTxt) {
    background-color: $bg;
    border: 2px solid $border;
    color: $txt;

    &:hover, &:focus, &#{$classEarlyBtn+"--active"} {
        background-color: $hovBG;
        border: 2px solid $hovBorder;
        color: $hovTxt;
    }
}

//--- Colors mixins ---
//Stay as mixin instead of %... to allow us to use it when we declare aliases
@mixin btn-early-color-white-blue {
    @include btn-early-color($white, $blue, $blue, $blue, $white, $white);

    &[data-toggle-status="on"] {
        @include btn-early-color($blue, $white, $white, $white, $blue, $blue);
    }
}

@mixin btn-early-color-primary {
    @include btn-early-color-white-blue();
}

@mixin btn-early-color-white-grey {
    @include btn-early-color($white, $grey, $grey, $light-grey, $grey, $grey);

    &[data-toggle-status="on"] {
        @include btn-early-color($light-grey, $grey, $grey, $white, $grey, $grey);
    }
}

@mixin btn-early-color-white-green {
    @include btn-early-color($white, $green, $green, $green, $green, $white);

    &[data-toggle-status="on"] {
        @include btn-early-color($green, $green, $white, $white, $green, $green);
    }
}

@mixin btn-early-color-white-red {
    @include btn-early-color($white, $red, $red, $red, $red, $white);

    &[data-toggle-status="on"] {
        @include btn-early-color($red, $red, $white, $white, $red, $red);
    }
}

@mixin btn-early-color-blue-white {
    @include btn-early-color($blue, $white, $white, $white, $blue, $blue);

    &[data-toggle-status="on"] {
        @include btn-early-color($white, $blue, $blue, $blue, $white, $white);
    }
}

@mixin btn-early-color-transparentWhite-blue {
    @include btn-early-color(transparent, $white, $white, $white, $blue, $blue);

    &[data-toggle-status="on"] {
        @include btn-early-color($white, $blue, $blue, transparent, $white, $white);
    }
}
//--- END colors ---

%btn-early-base {
    border-radius: 25px;
    font-size: $size-default;
    padding: 10px 24px;
    cursor: pointer;
}

%btn-early-icon {
    top: 4px;
    position: relative;
}

%btn-early-icon--invert-color {
    filter: brightness(0) invert(1);
}
%btn-early-icon--no-filter {
    filter: none;
}

%btn-early-icon-base--left {
    &::before {
        @extend %btn-early-icon;
        margin-right: 14px;
    }

    &:hover::before, &[data-toggle-status="on"]::before {
        @extend %btn-early-icon--invert-color;
    }
}

%btn-early-icon-base--right {
    &::after {
        @extend %btn-early-icon;
        margin-left: 14px;
    }

    &:hover::after, &[data-toggle-status="on"]::after {
        @extend %btn-early-icon--invert-color;
    }
}

@each $iconKey, $iconPath in $iconList {
    %#{"btn-early--icon-"+$iconKey} {
        mask-image: url($iconPath);
        -webkit-mask-image: url($iconPath);
        background-color: map-get($iconDefaultColor, $iconKey);
        mask-size: 20px 20px;
        -webkit-mask-size: 20px 20px;

        display: inline-block;
        width: 20px; 
        height: 20px;
        content:"";
    }

    %#{"btn-early--icon-"+$iconKey+'--before'} {
        @extend %btn-early-icon-base--left;

        &::before {
            @extend %#{"btn-early--icon-"+$iconKey};
        }

        @if $iconKey == 'arrow' {
            &::before {
                display: inline-block;
                transform: rotate(180deg);
            }
        }
    }
    %#{"btn-early--icon-"+$iconKey+'--after'} {
        @extend %btn-early-icon-base--right;

        &::after {
            @extend %#{"btn-early--icon-"+$iconKey};
        }
    }
}

@each $colorName, $colorPrefix in $colorList {
    %#{"btn-early--color--"+$colorName} {
        @include meta.apply(meta.get-mixin("btn-early-color-"+$colorName));
    }

    @each $iconKey, $iconPath in $iconList {
        %#{"btn-early-alias--"+$colorPrefix+"--b--"+$iconKey} {
            @extend %#{"btn-early--color--"+$colorName};
            @extend %#{"btn-early--icon-"+$iconKey+'--before'};

            @if $colorName == 'transparentWhite-blue' {
                &::before {
                    @extend %btn-early-icon--invert-color;
                }
                &:hover::before {
                    @extend %btn-early-icon--no-filter;
                }
            }
        }
        %#{"btn-early-alias--"+$colorPrefix+"--a--"+$iconKey} {
            @extend %#{"btn-early--color--"+$colorName};
            @extend %#{"btn-early--icon-"+$iconKey+'--after'};

            @if $colorName == 'transparentWhite-blue' {
                &::after {
                    @extend %btn-early-icon--invert-color;
                }
                &:hover::after {
                    @extend %btn-early-icon--no-filter;
                }
            }
        }
    }
}
