@use '../../base/scss/vars' as *;
@use "include-media" as im;

$className: ".early-alert";

%font-awesome {
    //Font awesome ".fa"
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    font-weight: 600;

    //override for us
    font-size: $size-alert-icon;
}

#{$className} {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 16px 0;
    padding: 15px;
    border: 1px solid $dark-blue;
    border-radius: 10px;
    font-size: $size-default;

    &--success {
        background-color: #26C281;
        color: $white;

        #{$className+"__icon"}::before {
            @extend %font-awesome;
            content: "\f00c";
        }
    }

    &--danger {
        background-color: #ed6b75;
        color: $white;

        #{$className+"__icon"}::before {
            @extend %font-awesome;
            content: "\f057";
        }
    }

    &--warning {
        background-color: #F4D03F;
        color: $black;

        #{$className+"__icon"}::before {
            @extend %font-awesome;
            content: "\f071";
        }
    }

    &--info {
        background-color: $white;
        color: $black;

        #{$className+"__icon"} {
            background-image: url('/theme/earlycareer/icons/info.svg');
        }
    }

    &__icon {
        display: inline-flex;
        position: absolute;
        height: 25px;
        width: 25px;

        background-position: center;
        background-repeat: no-repeat;
    }

    &__content {
        margin: 0;
        margin-left: 32px;

        &--no-icon {
            margin-left: 0px;
        }
    }
}