@use '../../base/scss/vars' as *;
@use "include-media" as im;

.layout-logged main:not(.layout-legacy)
{
    background: linear-gradient(180deg, #0086a2 700px, $white 700px);

    & .main-logged {
        &__header {
            margin: 16px 0;

            &__title {
                display: flex;
                align-items: center;

                &__icon {
                    display: block;
                    border-radius: 50%;
                    margin-right: 21px;
                    padding: 15px;

                    @include im.media('<xxsmall') {
                        display: none;
                    }
                }

                &__text {
                    color: $white;
                    text-transform: uppercase;
                    font-size: $size-title;
                    font-weight: bold;
                    margin: 0;
                }
            }
        }

        &__info {
            margin: 16px 0;
        }

        &__content__container {
            border-radius: 10px;
            background: $white;
            border: 1px solid $beige;
            box-shadow: 0px 3px 15px rgba(72, 71, 54, 0.16);
            padding: 32px;
            margin-bottom: 32px;
        }

        &__action-btn-end {
            margin-bottom: 32px;

            &__left {
                margin-bottom: 5px;
            }

            @include im.media('>small') {
                &__right {
                    display:flex;
                    justify-content: flex-end;
                }
            }
        }
    }
}
