@use '../../base/scss/vars' as *;

.saveOnFly {
    &::before {
        //Font awesome ".fa"
        display: inline-block;
        line-height: 14px;
        -webkit-font-smoothing: antialiased;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;

        font-weight: 600;
    }

    &.saveInProgress::before {
        content: "\f110"; //fa-spinner

        color: rgba(0, 134, 162, 1);

        //Font awesome ".fa-spin
        -webkit-animation:fa-spin 2s infinite linear;
        animation:fa-spin 2s infinite linear;
    }

    &.saved::before {
        content: "\f00c"; //fa-check

        color: #4a914b;
    }

    &.saveFailed::before {
        content: "\f00d"; //fa-times

        color: $red;
    }
}