@use "sass:list";
@use "sass:map";
@use "sass:meta";
@use "sass:selector";
@use '../../base/scss/vars' as *;
@use '../../base/scss/button_def' as btn;
@use "include-media" as im;

$legacyAliasMap: (
    btn.$classEarlyBtn+'-alias--p--a--arrow': (btn.$classEarlyBtn+'-alias--pa-right-arrow'),
    btn.$classEarlyBtn+'-alias--p--b--arrow': (btn.$classEarlyBtn+'-alias--pa-left-arrow'),
);

#{btn.$classEarlyBtn} {
    @extend %btn-early-base;

    &:not(#{btn.$classEarlyBtn+"--no-auto-width"}) {
        width: auto;
    }

    @include im.media("<small") {
        & {
            white-space: initial;
        }
    }

    &-max-w150 {
        max-width: 150px;
    }

    &-h50 {
        height: 50px;
    }

    &--mini {
        padding: 5px 10px;
    }

    &--default {
        @include btn.btn-early-color-white-grey();
    }

    &.btn-early-toggle>input[type="radio"] {
        display: none;
    }
}

#{btn.$classEarlyBtn+"--form-size"} {
    //!important because components-rounded.css which override all with this selector
    line-height: 1 !important;
}

@each $colorName, $colorPrefix in btn.$colorList {
    #{btn.$classEarlyBtn+"--color--"+$colorName} {
        @extend %#{"btn-early--color--"+$colorName};
    }

    @each $iconKey, $iconPath in btn.$iconList {
        $classBeforeID: "btn-early-alias--"+$colorPrefix+"--b--"+$iconKey;
        $classBefore: btn.$classEarlyBtn+"-alias--"+$colorPrefix+"--b--"+$iconKey;
        $classBefore: $classBefore+", "+btn.$classEarlyBtn+"-alias--"+$colorName+"--b--"+$iconKey;

        $classAfterID: "btn-early-alias--"+$colorPrefix+"--a--"+$iconKey;
        $classAfter: btn.$classEarlyBtn+"-alias--"+$colorPrefix+"--a--"+$iconKey;
        $classAfter: $classAfter+", "+btn.$classEarlyBtn+"-alias--"+$colorName+"--a--"+$iconKey;

        @if map.has-key($legacyAliasMap, $classBefore) {
            @each $legacyAlias in map-get($legacyAliasMap, $classBefore) {
                $classBefore: $classBefore+", "+$legacyAlias;
            }
        }
        @if map.has-key($legacyAliasMap, $classAfter) {
            @each $legacyAlias in map-get($legacyAliasMap, $classAfter) {
                $classAfter: $classAfter+", "+$legacyAlias;
            }
        }

        #{$classBefore} {
            @extend %#{$classBeforeID};
        }
        #{$classAfter} {
            @extend %#{$classAfterID};
        }
    }
}

#{btn.$classEarlyBtn+"--sm"} {
    font-size: $size-small;
    padding: 8px 16px;

    &::before, &::after {
        mask-size: $size-small $size-small;
        -webkit-mask-size: $size-small $size-small;
        width: $size-small; 
        height: $size-small;
        top: 2px;
    }

    &::before {
        margin-right: 7px;
    }
    &::after {
        margin-left: 7px;
    }
}

#{btn.$classEarlyBtn+"--xs"} {
    font-size: $size-xsmall;
    padding: 1px 5px;

    &::before, &::after {
        mask-size: $size-xsmall $size-xsmall;
        -webkit-mask-size: $size-xsmall $size-xsmall;
        width: $size-xsmall; 
        height: $size-xsmall;
    }

    //@TODO Not tested, maybe to adapt
    &::before {
        margin-right: 2px;
    }
    &::after {
        margin-left: 2px;
    }
}

#{btn.$classEarlyBtn+"--xxs"} {
    font-size: $size-xxsmall;
    padding: 1px 2px;

    &::before, &::after {
        mask-size: $size-xxsmall $size-xxsmall;
        -webkit-mask-size: $size-xxsmall $size-xxsmall;
        width: $size-xxsmall; 
        height: $size-xxsmall;
    }

    //@TODO Not tested, maybe to adapt
    &::before {
        margin-right: 2px;
    }
    &::after {
        margin-left: 2px;
    }
}

#{btn.$classEarlyBtn+"--info"} {
    @extend %#{"btn-early--color--white-blue"};
}
#{btn.$classEarlyBtn+"--danger"} {
    @extend %#{"btn-early--color--white-red"};
}
